@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins-Bold.otf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins-SemiBold.otf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.otf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins-Medium.otf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/Poppins-Light.otf");
}

.app.indigo-light .menu-item .mat-button .menu-icon {
    background: none !important;
    color: #6d7080;
}

.app.indigo-light .menu-item .mat-button .menu-title {
    color: #000 !important;
}

.app.indigo-light .menu-expand-icon {
    color: #6d7080 !important;
}

.app.indigo-light .mat-toolbar {
    background: linear-gradient(45deg, #0063be, #008e9c);
    color: #fff;
}

.app.indigo-light .top-toolbar,
.app.indigo-light .landing-toolbar {
    background: linear-gradient(50deg, #0063be, #019595) !important;
    color: #e8eaf6;
}

.app.indigo-light .menu-item .mat-button.active-link .menu-icon {

    color: #303f9f;
}

.app.indigo-light .menu-item .mat-button .menu-title.active-link {
    background: rgba(0, 0, 0, 0.12);
    color: #303f9f;
}

.mat-icon-button i,
.mat-icon-button .mat-icon {
    line-height: 24px;
    color: #fff;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 56px !important;
}

.mat-sort-header-content {
    text-align: center;
    display: flex;
    align-items: center;

    font-size: 13px !important;
    font-family: Poppins;
}

.mat-raised-button .small {
    font-size: 11px;
    font-family: 'Poppins';

}

.app.indigo-light .mat-drawer-container {
    background-color: #f1f1f1;
    color: rgba(0, 0, 0, 0.87);
}

.mat-form-field {
    font-size: inherit;
    font-weight: 400;
    font-family: Poppins-Medium;
    font-size: 13px;
}

.app.indigo-light .breadcrumb a {
    color: #000;
    font-family: Poppins-Medium;

}

.mat-card {
    font-family: Poppins-Medium;
}

h2.title {
    font-size: 18px;
   
}
p {
    font-family: 'Poppins';
    line-height: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.p-text-01 {
    display: flex;

}
.p-text-01 img {
    width: 150px;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 8px;
}

.mat-form-field-flex {

    max-height: 45px;
}
ul.resources-list {

    padding: 0px;
    margin: 0px;
    list-style: none;
}

ul.resources-list li {
    font-size: 14px;
    // color: #686868;
    font-family: Poppins;
    line-height: 27px;
    text-decoration: none;

}


ul.resources-list li a:hover {
    font-size: 13px;
    color: #0789a7;
    font-family: Poppins;
    line-height: 35px;

}
i.material-icons.pdf-icon {
    position: relative;
    top: 8px;
    right: 10px;
    color: #0789a7;
}
.mat-form-field-appearance-outline .mat-form-field-label {
    top: 22px;
    margin-top: -0.25em;
}
span.information-box {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 5px;
    background: rgb(164 162 229 / 30%);
    padding-top: 10px;
    margin-top: 10px;
    display: flex;
    text-align: center;
}

.Videos-box {
    border: 4px solid #eee;
    padding: 6px;
    width: 290px;
    border-radius: 5px;
}

.Videos-box img{
    width: 100% !important;
    height: 100%;
    margin-left: 0rem;
    align-content: center;
    border-radius: 10px;
  }
  .demo {
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 16px;
    color: #0066b4;
  }
input.mat-input-element {
    position: relative;
    top: -5px;
}

.app.indigo-light .mat-flat-button.mat-accent,
.app.indigo-light .mat-raised-button.mat-accent,
.app.indigo-light .mat-fab.mat-accent,
.app.indigo-light .mat-mini-fab.mat-accent {
    background-color: #f98207;
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: auto !important;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    margin-left: 15px !important;
    border-radius: 50%;
}

.mat-paginator-page-size-label {
    margin: 0 4px;
    font-family: 'Poppins-Medium';
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {

    font-family: Poppins;
   
}


.mat-dialog-content {
    background: linear-gradient(45deg, #0063be, #008e9c) !important;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: 20px !important;
    margin: 0px !important;
   
}

.mat-dialog-container {
 
   padding: 0px !important; 
}

button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-profile {
    min-width: 40px !important;
    height: 40px !important;
    line-height: 24px;
    background: #fff;
    color: #000;
    font-family: "Poppins-Regular";
}
.total-text {
    text-align: center;
    line-height: 25px;
    font-size: 18px;
    color: #f00;
}
span.total-amout {
    color: #06cd08;
}
.model-table-bg-red{
    background: #f41212;
    color: #fff;
}

.model-table-bg-green{
    background: #06cd08;
    color: #fff;
}
.copy-r a {
    color: #000;
    font-size: 12px;
    font-family: "Poppins";
    text-decoration: none;
}
.app.indigo-light .menu-item .mat-button.active-link .menu-title {
    color: #303f9f !important;
}

.app.indigo-light .menu-item .mat-button.active-link {
    background: rgb(134 134 141 / 6%);
}
.scans-search__icon {
    position: absolute;
    top: 18px;
    left: 35px !important;
}

.scans-search__icon.icons20 {
    position: absolute;
    top: 18px;
    left: 20px !important;
}

.egret-navy-dark .mat-bg-warn {
    background-color: #ff3d57;
    display: none;
}
span.year-p {
    text-align: center;
    padding-bottom: 10px;
    display: inline-block;
}
.btn-info {
    background: #228cdc!important;
}
.btn-success {
    background: #028f17 !important;
}
.notification-number {
    position: absolute;
    top: 8px;
    right: 30px;
    width: 20px;
    display: none;
    height: 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
}
.green-bt {
    background: #3c9c09 !important;
    color: #fff !important;
    margin-bottom: 15px !important;
}
.close-bt {
    float: right;
    height: 30px !important;
    width: 30px !important;
    background: rgb(255 255 255 / 12%);
    border-radius: 50px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    left: 13px;
    
    position: relative;
    top: 10px;
    // border: 1px solid #fff;



}
span.close-icons {
    float: right;
    position: relative;
    top: -23px;
    color: red;
    font-family: "Poppins-Medium";
    font-size: 16px;
    cursor: pointer;
}
.add-bt{
height: 40px;
position: relative;
top: 5px;
}
mat-footer-row, mat-header-row, mat-row {
    border-bottom-width: 5px !important;
  }
  
.mat-tab-labels {
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    
   
  
  
}
.bg-f {
    background: #fff;
}

.mat-form-field {
    display: inline-block;
    position: relative;
    text-align: left;
    width: 100%;
  }
 .card-scroll{
    overflow: auto;
    min-height: 480px
  }
  .card-bg{
    background: #fff;
  }

  .mat-tab-label .mat-tab-label-content {
 font-family: 'Poppins';
}

  .mat-header-cell {
    font-size: 13px;
    font-family: "Poppins";
    color:#000 !important;
    font-weight: bold;
}
span.mat-text {
    font-size: 14px;
    line-height: 25px;
    font-family: 'Poppins';
    text-align: justify;
}
span.information-box a {
    color: #082d8f;
    text-decoration: none;
}

span.information-box-01 a {
    /* font-size: 14px !important; */
    line-height: 35px;
    text-decoration: none;
}
span.information-box-01 {
    margin-left: 15px;
    display: block;
}

.Videos-box.right-side {
    width: 300px;
    margin-top: 30px;
    display: block;

}

.p-30{
    padding: 30px !important;
}

.bt-t {
    display: inline-block !important;
    margin-top: 15px !important;
}

span.headertxt {
    padding-bottom: 6px;
    display: flex;
    font-family: 'Poppins-Regular';
    color: #48465b;

}
span.dashboard-text {
    font-size: 12px;
}
.m-h320 {
    min-height: 245px;
}

.mt-2 {
    padding: 9px 16px;
}
.register-11 {
    background: url(../../../assets/img/users/bg-r.jpg) top left repeat;
    background-size: cover;
    min-height: 100vh;
}

.register-side-bg {
    background: #fff;
    border-radius: 5px;
    position: relative;
    top: 80px;
}

p.login-r {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #0f7ddb;
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px !IMPORTANT;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
}

span.price-01 {
    font-size: 18px;
    padding-top: 14px;
    display: flex;
    color: #1c77ad;
}
ul.resources-list li a {

    color: #686868;

}
.subhead
{
    font-size: 14px;
    color: #000;
    font-weight: bold;
}
.popupfont
{
    font-size: 14px;
    color:#000;
}
.mat-cell, .mat-footer-cell
{
    color: #000 !important;
}
.datainfo
{
  text-align: center;
  padding: 10px;
  font-size: 12px !important;
  font-weight: 900;
}

.icon08 {
    position: relative;
    top: -5px;
  }
  .login-bg01 {
    background: url("../../../assets/img/users/auth-bg.jpg");
    background-position: center; 
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; 
  }
  .life-v {
    margin: 0px auto;
    width: 55%;
    position: relative;
    top: 75px;
  }
  .life-book-demo-box {
    text-align: center;
    margin: 0 auto;
    padding-top: 90px;
  }
  .life-book-demo-box h2 {
    color: red;
  }
  .life-book-demo-box p {
    font-size: 15px;
    line-height: 25px;
  }
  .life-book-demo-b .subheading
{
    font-size: 13px;
    color:#000;
}
h2 .subheading
{
    font-size: 13px !important;
    color:#000;
}