@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';
 
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css"; 
@import "../node_modules/leaflet/dist/leaflet.css";

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";
@import "app/theme/styles/custom";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.w-full {
    width: 100%;
  }
  
  span.titlebar-no {
    color: #ffffff;
    padding-left: 5px;
    font-size: 13px;

}

.small-bt {
  font-size: 11px;
  line-height: 25px !important;
  width: 60px !important;
  padding: 2px !important;
  font-family: poppins-light;
}
.mat-form-field-label-wrapper {

  overflow: inherit !important;

}
.small-bt01 {
  font-size: 11px;
  line-height: 25px !important;
  /* width: 60px !important; */
  padding: 2px !important;
  font-family: poppins-light;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background: #38a0c9 !important;
}
h3.top-title {
  color: #2376c8;
  font-family: 'Poppins-Light';
  font-size: 15px;
  border-bottom: 1px solid #2376c8;
}
  .mat-sort-header-button {
    font-weight: bold !important;
    font-size: 14px !important;
  }
  
  th.mat-header-cell,
  td.mat-cell {
    padding: 0 0.75rem !important;
    white-space: nowrap;
  }
  
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 24px !important;
  }
  
  .mat-snack-bar-container {
    margin: 0 !important;
    min-width: 300px !important;
  }
  
  .mat-snack-bar-handset .mat-snack-bar-container {
    width: 96% !important;
    margin: auto !important;
    margin-top: 80px !important;
  }
  .nt {
    padding: 20px !important;
    background-color: #fff;
    color: #313131;
    margin: 0;
    margin-top: 80px !important;
    font-size: 16px;
    border-radius: 2px !important;
  }
  .nt_success {
    border-left: 10px solid #4caf50;
    button {
      color: #4caf50;
    }
  }
  
  .nt_info {
    border-left: 10px solid #00bcd4;
  
    button {
      color: #00bcd4;
    }
  }
  
  .nt_warning {
    border-left: 10px solid #ffc107;
  
    button {
      color: #ffc107;
    }
  }
  
  .nt_error {
    border-left: 10px solid #f44336;
    button {
      color: #f44336;
    }
  }
  
  .mat-simple-snackbar{
    white-space: pre-wrap !important
  }  
  
  
  .bg-app-bar {
    z-index: 999; 
    background: linear-gradient(50deg, #0063be, #019595) !important;
    border-radius: 5px 5px 0px 0px;
  }
  
  .avatar {
    margin: 0 0 0 11px !important;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .mat-cell, .mat-footer-cell {
    font-size: 12px;
    font-family: 'Poppins';
    // color: #595d6e !important;
    color:#000 !important;
  }
  .bg-app-bar {
    .mat-mini-fab {
      .mat-button-wrapper {
        padding: 0 !important;
      }
    }
  }
  
  .titlebar {
    margin-top: 0rem;
  }
  
  .rounded-full {
    border-radius: 9999px;
    padding: 10px 40px;
  }
  
  .overflow-auto {
    overflow: auto;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-visible {
    overflow: visible;
  }
  .overflow-scroll {
    overflow: scroll;
  }
  .overflow-x-auto {
    overflow-x: auto;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .overflow-y-hidden {
    overflow-y: hidden;
  }
  .overflow-x-visible {
    overflow-x: visible;
  }
  .overflow-y-visible {
    overflow-y: visible;
  }
  .overflow-x-scroll {
    overflow-x: scroll;
  }
  .overflow-y-scroll {
    overflow-y: scroll;
  }
  .scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }
  .scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }
  
  .headerlinks {
    width: 100%;
    text-align: right;
    a {
      margin-right: 10px;
      font-size: 24px !important;
      text-align: -webkit-center;
    }
  }
  
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display: block;
    //vertical-align: middle;
  }
  
  b {
    font-weight: bolder;
    //font-size: 1rem;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }
  
  [role="button"],
  button {
    cursor: pointer;
  }
  button {
    background: 0 0;
  }
  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  input {
    overflow: visible;
  }
  
  .bg-pattern {
    background: url("Images/loginbg.jpg");
    height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  
  .twocolumnCheckBoxList .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    line-height: 40px !important;
  }
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .horizontal-menu-item {
      width: auto !important;
    }
  
    .mat-toolbar-row,
    .mat-toolbar-single-row {
      padding: 0px 4% 0px 16px !important;
    }
  }
  
  @media screen and (max-width: 599px) { 
    .landing-footer{
      height: 100px !important;
      padding: 0px !important;
      //margin: 5px 0px !important;
      background: white !important;
    }
    .mat-drawer-container{
      background-color: #eeeeee !important;
    }
  }
  
  .iconImg {
    //height: -webkit-fill-available;
    margin-top: -4px;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    width: 16px;
    height: 16px;
  }
  .iconImg.stock {
    height: -webkit-fill-available;
    width: 24px;
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-base {
    font-size: 1rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .text-4xl {
    font-size: 2.25rem;
  }
  
  .text-5xl {
    font-size: 3rem;
  }
  
  .text-6xl {
    font-size: 4rem;
  }
  
  .invisible {
    visibility: hidden;
  }
  .filter-menu {
    min-width: 130px;
  }
  .Norecord {
    text-align: center;
    font-size: x-large;
  }
  
  .mat-icon {
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    /* Support for IE. */
    font-feature-settings: "liga";
  }
  
  .ngxImageZoomContainer {
    width: 200px !important;
    height: auto !important;
  }
  .pointer {
    cursor: pointer;
  }
  
  input:focus {
    outline: none !important;
  }
  
  textarea:focus {
    outline: none !important;
  }
  
  h2.title {
    font-size: 18px;
        color: #fff;
  }
  
  span.title{
    color: black;
  }
  
  .mat-icon {
    vertical-align: middle;
  }
  
  .ngx-floating .country-selector {
    width: fit-content !important;
    margin-left: -10px !important;
  }
  .ngx-mat-tel-input-container input:not(.country-search){
    padding: 0 6px 0 60px !important;
  }
  .cc-window{
    z-index: 9999999999;
    border-radius: 10px;
  }
  
  .ThreeDButton{
    box-shadow: -4px 5px 2px #00000073;
  }
  .app.indigo-light .mat-tab-label, .app.indigo-light .mat-tab-link {
    color: #fff;
    opacity: inherit;
    margin-left: 3px;
    border-radius: 5px 5px 0px 0px;
    font-family: "Poppins-Medium";
    background: #0862b5;
    font-size: 11px;
    padding: 0 10px;
    height: 30px !important
}
  


.w-100 {
  width: 100% !important;

}
.right-side-bg {
  background: #fff;
}

i.material-icons.red {
  color: #da0e0e;
  padding-right: 10px;
  cursor: pointer;
}.orange {
  color: #f98207;
  padding-right: 10px;
  cursor: pointer;
}

.block {
  color: #000;
  padding-right: 10px;
  cursor: pointer;
}
i.material-icons.blue {
  color: #3f51b5;
  padding-right: 10px;
  cursor: pointer;
}


.arrow-icon {
  position: relative;
  top: 5px;
  color: #0d91cc;
  font-size: 18px;

}
i.pink{
  color: #ee106d;
  cursor: pointer;
}
.example-button-row{
  display: flex;
  position: initial;
  right: 0px;
  z-index: 999;
  cursor: pointer;

}
.bg-deep-purple {
  background: #673AB7 !important;
  color: #fff !important;
}
.right-side-bt {
  float: right;
}
.mr-10{
  margin-left: 10px !important;
  
}

.bg-icon-white {
  background: #fff;
  padding: 10px;
  border: 1px solid #d6d0d0;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.bg-icon-white:hover {
  background: #04b7d4;
  color: #fff;

}
.mr-10{
  margin-right: 10px;
}
.mr-5{
  margin-right: 1px !important;
}
.mr-15{
  margin-right: 15px;
}
.mt-10{
  margin-top: 10px !important; 
}

.h-280{
  height: 280px;
  overflow-y: auto;
}
.h-580{
  height: 580px;
}
.h-750{
  height: 750px;;
  // overflow-y: auto;
}
 .h-820{
  height: 820px;;
  overflow-y: auto;
 }

.h-920 {
  height: 920px;
}
.mb-20{
  margin-bottom: 20px !important; 
}
h3.kt-head__title.bg-1 {
  background: #4298eb;
  padding: 25px;
  margin-top: -16px;
  margin-right: -16px;
  margin-bottom: 10px;
  font-size: 16px;
  margin-left: -16px;
  color: #fff;
}
.h-titile {
  font-family: 'Poppins-Regular';
  font-size: 16px;
}
.desc {
  font-family: 'Poppins';
  font-size: 13px;
  color: #000;
  line-height: 22px;
}
.price {
  font-size: 17px;
  font-family: 'Poppins-Medium';
  color: #eb1212;
}
.bg-gry {
  border-bottom: 1px solid #ccc5c5 !important;
}
.h-580 {
  height: 580px !important;
}
.mt-40{
  margin-top: 40px;
}

.customers {

  border-collapse: collapse;
  width: 100%;
}

.customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.customers tr:nth-child(even){background-color: #fff;}

.customers tr:hover {background-color: #ddd;}

.customers th {
  padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #3163d1;
    color: #fff;
    padding-left: 10px;
}
.blue-colur-link{
  color: #0063be;
  cursor: pointer;
}

.red-colur-link{
  color: #ff3d57;
  cursor: pointer;
  font-weight: 600;
}
.step1Buttons.right-side-bt {
  margin-top: 10px;
}

.bg-orange {
  background: #FF9800 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
p.p-text {
  color: #04b7d4;
}
.kt-head.kt-bg-brand.bg {
  background: #c8deef;
  padding: 22px;
  margin: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;

}
.mat-card-subtitle, .mat-card-content {
  font-size: 13px;
  font-family: 'Poppins';
  color: #000 !important;
}
.bg-deep-orage.right-bt {
  background: #ff860e!important;
  float: right;
  /* margin: 0px; */
}

.bg-button {
  padding: 8px 16px !important;
}

.bg-deep-light-green {
  background: #86d302 !important;
}

.mat-tab-header-rtl .mat-tab-header-pagination-before, .mat-tab-header-pagination-after {
  padding-right: 9px;
  margin-top: 10px;
  background: #fff !important;
}
.mat-tab-header-pagination-before, .mat-tab-header-rtl .mat-tab-header-pagination-after {
  padding-left: 4px;
  margin-top: 10px;
  background: #fff !important;
}

mat-footer-row, mat-header-row, mat-row {
  border-bottom-width: 5px;
}

.mat-table {
  font-family: 'Poppins' !important;
}

.mat-cell {
  border-spacing: 10px;
}
.login-img {
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}
.b-15{
  margin-bottom: 15px;
    margin-top: 15px;
}

  p.login-p {
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Poppins-Medium';
    padding-top: 20px;
    text-align: left;
}

.Taxable-bt{
  height: 46px;
  margin-top: -8px;
  position: relative;
  top: -6px;
}
p.p-stext {
  text-align: center;
  font-size: 13px;
  color: #5e8895;
}
.p-text05 {
  margin-left: 5px;
}
.mat-tab-label {
  height: 40px !important;
  padding: 0 22px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 60px !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}

.app.indigo-light .mat-tab-group.mat-primary .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ff860e;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {

.life-book-demo-box {
  padding-bottom: 20px;
  padding-top: 40px;
}
.life-v {
  margin: 0px auto;
  width: 80%;
  position: relative;
  top: 25px;
}
.life-v iframe {
  width: 100%;
  height: 200px;
}
}
@media (max-width: 576px)  { 

  .life-book-demo-box {
    padding-bottom: 20px;
    padding-top: 40px;
  }
  .life-v {
    margin: 0px auto;
    width: 80%;
    position: relative;
    top: 25px;
  }
  .life-v iframe {
    width: 100%;
    height: 200px;
  }
  .h-100 {
    height: 100% !important;
    display: block !important;
  }
  }
  @media (max-width: 926px)  { 

    .life-book-demo-box {
      padding-bottom: 20px;
      padding-top: 40px;
    }
    .life-v {
      margin: 0px auto;
      width: 80%;
      position: relative;
      top: 25px;
    }
    .life-v iframe {
      width: 100%;
      height: 200px;
    }
    .h-100 {
      height: 100% !important;
      display: block !important;
    }
    }
    angular-editor-toolbar button i {
      font-family: FontAwesome !important;
      color: #474d6d !important;
      
      }